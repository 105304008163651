import React from 'react';
import Meta from './Meta';

function About() {
  return (
    <div>
      <Meta title="About Page" description="Learn more about us on this page" />
      <h1>About</h1>
      <p>This is the about page.</p>
    </div>
  );
}

export default About;
