import React from 'react';
import Meta from './Meta';

function NotFound() {
  return (
    <div>
      <Meta title="About Page" description="Learn more 404 on this page" />
      <h1>NotFound</h1>
      <p>404.</p>
    </div>
  );
}

export default NotFound;
