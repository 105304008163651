import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Meta from './Meta';

function Home() {
  const count = useSelector(state => state.counter.count);
  const dispatch = useDispatch();
  const apiUrl = process.env.REACT_APP_API_URL;

  return (
    <div>
      <Meta title="Home Page" description="This is the home page of my app" />
      <h1>Home</h1>
      <p>Count: {count}</p>
      <p>API URL: {apiUrl}</p>
      <button onClick={() => dispatch({ type: 'INCREMENT' })}>Increment</button>
      <button onClick={() => dispatch({ type: 'DECREMENT' })}>Decrement</button>
    </div>
  );
}

export default Home;
